import React, { Component, createRef } from 'react'
import '../scss/Canvas.scss'
import CanvasProxy from '../proxies/CanvasProxy'

import { emitter } from '../utils'

const ref = createRef()

export default class Canvas extends Component {
  constructor(props) {
    super(props)
    this.state = { cursor: 'pointer' }
  }
  componentDidMount() {
    const { points, drawing } = CanvasProxy
    CanvasProxy.init(ref)
    if (points || drawing) {
      CanvasProxy.points = points
      CanvasProxy.drawing = drawing
      // CanvasProxy.updateBounds() // TODO
      CanvasProxy.updateStrokes()
    }
    emitter.on('startEndScenario', () => {
      this.setState({ cursor: 'pointer' })
    })
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.cursor !== nextState.cursor
  }

  render() {
    return <canvas draggable={false} ref={ref} style={this.state} />
  }
}
