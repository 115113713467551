import ReactGA from 'react-ga'
import sample from 'lodash.sample'
import copy from '../resources/copy.json'

/* eslint-disable no-unused-vars, no-use-before-define, object-curly-newline */
import constants from './constants'
import SpeechProxy from '../proxies/SpeechProxy'
import AutoDrawProxy from '../proxies/AutoDrawProxy'
import {
  getCurrentLevelCopyItems,
} from '../selectors/scenario'
import { getAudioFilename, emitter } from '../utils'
import settings from '../settings'

const atEndLevel = () => true

export const loadScenario = () => async (dispatch, getState) => {
  await dispatch({ type: constants.LOAD_SCENARIO, data: copy })
}

// checking if we're ready for new copy
export const isReadyForCopy = (copy = []) => (dispatch, getState) => {
  const { app, scenario } = getState()
  // Always stop item after 15 sec
  const timeSinceScenarioUpdate = Date.now() - scenario.prevUpdate
  if (timeSinceScenarioUpdate > settings.maximumCopyTime) dispatch(stopCopyItem())

  if (copy.length === 0) return false
  if (atEndLevel()) return true
  if (scenario.item || app.paused) return false
  return true
}

export const onCopyStarted = (item, canvasTimestamp) => (dispatch) => {
  ReactGA.pageview(`/copy/${getAudioFilename(item.text)}`, [], item.text)
  dispatch({
    type: constants.START_COPY_ITEM,
    data: {
      item,
      canvasTimestamp,
    },
  })
}

export const startCopyItem = (items = [], { canvasTimestamp, levelUpItem } = {}) => async (
  dispatch,
  getState,
) => {
  let item = sample(items)
  if (!item) return

  const isReady = await dispatch(isReadyForCopy(items))
  if (!isReady) return

  // we want to play a level up message before the actual warning copy
  let nextAudioFile
  // NOTE: this only works because we only have 1 warning per level
  // we need to make sure we have a warning item and it's not the last level
  if (item.warning && levelUpItem) {
    nextAudioFile = item
    item = levelUpItem
  }

  // a copy item can be related to a timestamp which represents the last change to the canvas
  // this connection tells us if the copy already repsonded to the current state of the canvas
  // in case there's no associated state/timestamp we reset the silence threshold for timed copy
  if (canvasTimestamp) {
    dispatch({ type: constants.SET_SILENCE_THRESHOLD, data: settings.defaultSilenceThreshold })
  }
  SpeechProxy.say(
    item,
    () => {
      dispatch(onCopyStarted(item, canvasTimestamp))
    },
    async () => {
      dispatch(stopCopyItem())
      if (nextAudioFile) {
        dispatch(startCopyItem([nextAudioFile]))
      }
    },
  )
}

export const stopCopyItem = () => async (dispatch, getState) => {
  const state = getState()
  const { scenario } = state
  if (!scenario.item || SpeechProxy.isPlaying) return
  await dispatch({ type: constants.STOP_COPY_ITEM })
  if (!atEndLevel()) {
    dispatch(startTimer())
  }

  emitter.emit('copyStopped')
}

export const startTimer = () => (dispatch) => {
  dispatch({ type: constants.START_TIMER })
}

export const stopTimer = () => (dispatch) => {
  dispatch({ type: constants.STOP_TIMER })
}

export const resetScenario = () => (dispatch, getState) => {
  dispatch({ type: constants.RESET_SCENARIO })
}

export const startFinalCopyItems = () => (dispatch, getState) => {
  const copyItems = getCurrentLevelCopyItems(getState())

  const augmented = copyItems.map(c => (c.hasCategory ? { ...c, category: sample(settings.penisReplacements) } : c))
  dispatch(startCopyItem(augmented))
}

export const startEndScenario = () => (dispatch) => {
  emitter.emit('startEndScenario')
  let finishedDrawings = 0
  AutoDrawProxy.start()

  emitter.on('autoDrawingFinished', () => {
    setTimeout(() => {
      AutoDrawProxy.startErasing()
      finishedDrawings += 1
      if (finishedDrawings === 10) {
        dispatch({ type: constants.TOGGLE_SHOP_LINK })
      }
    }, 1000)
  })

  // we want to initialise the next drawing when the eraser is on the way back
  emitter.on('erased', () => AutoDrawProxy.nextDrawing())
}