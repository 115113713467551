/* eslint-disable no-unused-vars, no-use-before-define, object-curly-newline */
import { push } from 'react-router-redux'
import ReactGA from 'react-ga'
import constants from './constants'
import {
  loadScenario,
  resetScenario,
  stopCopyItem,
  stopTimer,
  startTimer,
  startEndScenario,
} from './scenario'
import { storage, sleep } from '../utils'
import SpeechProxy from '../proxies/SpeechProxy'

export const initApp = () => async (dispatch, getState) => {
  dispatch({ type: constants.INIT_APP })
  dispatch(loadScenario())
  dispatch({ type: constants.READY_APP })
  ReactGA.event({ category: 'App', action: 'Initialize app', nonInteraction: true })
}

export const resetApp = () => async (dispatch, getState) => {
  dispatch({ type: constants.RESET_APP })
  dispatch(resetScenario())
  await dispatch(push('/'))
  storage.removeAll()
  ReactGA.event({ category: 'App', action: 'Reset' })
  window.location.reload()
}

export const pauseApp = () => (dispatch, getState) => {
  dispatch({ type: constants.PAUSE_APP })
  dispatch(stopTimer())
}

export const resumeApp = () => (dispatch, getState) => {
  dispatch({ type: constants.RESUME_APP })
  dispatch(startTimer())
  dispatch(stopCopyItem())
  dispatch(startEndScenario())
}

export const hideIntroScreen = () => (dispatch, getState) => {
  const { app } = getState()
  if (!app.hideIntroScreen) {
    SpeechProxy.init()
    dispatch({ type: constants.HIDE_INTRO_SCREEN })
  }
}
