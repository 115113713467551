import constants from '../actions/constants'
import settings from '../settings'

const savedState = {}

const initialState = {
  initialized: false,
  copy: [],
  history: savedState.scenario ? savedState.scenario.history : [],
  item: undefined,
  latestItem: savedState.scenario ? savedState.scenario.latestItem : undefined,
  time: Date.now(),
  prevUpdate: Date.now(),
  silenceThreshold: settings.defaultSilenceThreshold,
  canvasTimestamp: Date.now(),
}

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LOAD_SCENARIO:
      return {
        ...state,
        initialized: true,
        copy: action.data
          .filter(Boolean)
          .filter(e => e.text.length)
          .map((e, i) => ({
            ...e,
            index: i,
            hasCategory: e.text.includes('[category]'),
            time: e.time.trim() === 'x',
            levelup: e.levelup.trim() === 'x',
            warning: e.warning.trim() === 'x',
            level: Number(e.level),
          })),
        levels:
          action.data
            .filter(Boolean)
            .map(e => Number(e.level))
            .reduce((acc, cur) => {
              if (!acc.includes(cur)) {
                acc.push(cur)
              }
              return acc
            }, []).length - 1, // minus 2 because first and last level don't count
      }

    case constants.START_COPY_ITEM:
      return {
        ...state,
        item: action.data.item,
        latestItem: action.data.item,
        history: state.history.concat(action.data.item.index),
        prevUpdate: Date.now(),
        canvasTimestamp: action.data.canvasTimestamp
          ? action.data.canvasTimestamp
          : state.canvasTimestamp,
      }

    case constants.STOP_COPY_ITEM:
      return {
        ...state,
        item: undefined,
      }

    case constants.START_TIMER:
      return {
        ...state,
        timerStart: Date.now(),
      }

    case constants.STOP_TIMER:
      return {
        ...state,
        timerStart: undefined,
      }

    case constants.SET_SILENCE_THRESHOLD:
      return {
        ...state,
        silenceThreshold: action.data,
      }

    case constants.RESET_SCENARIO:
      return {
        ...initialState,
        initialized: true,
        copy: state.copy,
        history: [],
      }

    default:
      return state
  }
}
