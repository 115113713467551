import axios from 'axios'
import { TweenMax } from 'gsap/TweenMax'

import DocumentProxy from './DocumentProxy'
import CanvasProxy from './CanvasProxy'
import ToolProxy from './ToolProxy'
import { emitter, remap } from '../utils'
import settings from '../settings'

class AutoDrawProxy {
  drawings = []
  currentStroke = null
  strokes = null
  startTime = null
  rAF = null
  hasStarted = false
  isDrawing = false

  constructor() {
    this.draw = this.draw.bind(this)
    this.startStroke = this.startStroke.bind(this)
    this.eraseDrawing = this.eraseDrawing.bind(this)
  }

  // x and y of drawing data are mapped to 0 - 1
  // here we map them to the screen
  xyToScreen(x, y) {
    const { sx, sy, width, height } = this.size
    return {
      x: sx + x * width,
      y: sy + y * height,
    }
  }

  // getting semi-random drawings from files
  async getDrawings() {
    const number = Math.floor(Math.random() * 100)
    const { data } = await axios.get(`${process.env.PUBLIC_URL}/assets/drawing-data/${number}.json`)
    // TODO: look into why some drawings don't have strokes
    // we need to parse json for some properties
    const drawings = data
      .map(d => ({
        ...d,
        bounds: JSON.parse(d.bounds),
        drawing: JSON.parse(d.drawing),
        screen: JSON.parse(d.screen),
      }))
      .map(d => ({
        // make sure drawing/stroke is valid
        ...d,
        drawing: d.drawing.filter(stroke => stroke.length > 1),
      }))
      .filter(d => d.drawing.length && d.bounds)
    this.drawings = [...this.drawings, ...drawings]
  }

  async start() {
    if (!this.hasStarted) {
      // stop listing to mouse move events as we're starting to draw automatically
      DocumentProxy.removeListener(
        document,
        ['mousemove', 'touchmove'],
        DocumentProxy.registerMouseOrTouchMove,
      )
      CanvasProxy.removeListeners()

      await this.getDrawings()
      this.initDrawing(this.drawings.pop())
      this.hasStarted = true
    }
  }

  stop() {
    if (this.hasStarted) {
      this.hasStarted = false
    }
  }

  nextDrawing = () => {
    if (this.hasStarted) this.initDrawing(this.drawings.pop())
  }

  initDrawing(data) {
    const { drawing, bounds } = data

    // we want to control how big the drawing will be drawn
    // so here we calculate dimensions and position
    let width = Math.abs(bounds.xMax - bounds.xMin)
    let height = Math.abs(bounds.yMax - bounds.yMin)
    const maxWidth = Math.min(window.innerWidth * 0.9, width)
    const maxHeight = Math.min(window.innerHeight * 0.9, height)
    const resizeRatio = Math.min(maxWidth / width, maxHeight / height)
    width = Math.round(width * resizeRatio)
    height = Math.round(height * resizeRatio)

    this.bounds = bounds
    this.size = {
      width,
      height,
      sx: (window.innerWidth - width) / 2,
      sy: (window.innerHeight - height) / 2,
    }

    // images seem to be stored with x and y between 0 and 1
    // but 0 and 1 values almost never occur (?)
    // to be in control of width and height we map minimum to 0 and max to 1
    // TODO: this would not be necessary if every drawing would have the correct bbox
    const xPoints = drawing.flat().map(p => p.x)
    const yPoints = drawing.flat().map(p => p.y)
    const strokeMinX = Math.min(...xPoints)
    const strokeMaxX = Math.max(...xPoints)
    const strokeMinY = Math.min(...yPoints)
    const strokeMaxY = Math.max(...yPoints)

    // apply scaling and filter points that have no timestamp
    this.strokes = drawing
      .map(stroke =>
        stroke.filter(p => !!p.t).map(p => ({
          ...p,
          x: remap(p.x, strokeMinX, strokeMaxX, 0, 1),
          y: remap(p.y, strokeMinY, strokeMaxY, 0, 1),
          tool: 'Pencil',
        })))
      .filter(stroke => stroke.length > 0)
    this.currentStroke = 0

    this.initPencil()

    // load new drawings if we run out of them
    if (this.drawings.length < 5) {
      this.getDrawings()
    }
  }

  initPencil() {
    // use the last known mouse position as a start point to animate the pencil from
    const from = DocumentProxy.mousePosition || { x: 0, y: 0 }

    // we need to animate the pencil to the first point of the drawing
    const { x, y } = this.strokes[0][0]
    // in case this is not the first drawing we don't use the last known mouse position
    this.pencilPosition = this.pencilPosition || from

    // animate the pencil
    this.movePencil({
      from: this.pencilPosition,
      to: this.xyToScreen(x, y),
      time: 500,
      cb: this.startStroke,
    })
  }

  async eraseDrawing() {
    // set the drawing manually, otherwise there's nothing to erase
    const { sx, sy, width, height } = this.size
    CanvasProxy.drawing = this.strokes
    CanvasProxy.updateBounds({
      sx,
      sy,
      w: width,
      h: height,
    })
    // erasing the canvas
    await CanvasProxy.eraseCanvas()
  }

  startErasing() {
    const { x, y } = this.lastPoint
    this.eraseDrawing()
    // we need to animate the pencil back to the initial position
    this.movePencil({
      from: this.xyToScreen(x, y),
      to: {
        x: CanvasProxy.eraserStartPosition.x - 80,
        y: CanvasProxy.eraserStartPosition.y + 70,
      },
      time: 500,
    })
  }

  movePencil({ from, to, time = 0, cb = () => {} }) {
    // no need to use TweenMax when there's no time
    if (time === 0) {
      // the pencil component is listening to this event
      emitter.emit('mousePosition', {
        x: to.x,
        y: to.y,
      })
      cb()
    } else {
      TweenMax.fromTo(this.pencilPosition, time / 1000, from, {
        ...to,
        onUpdate: () => {
          // update the pencil position
          emitter.emit('mousePosition', this.pencilPosition)
        },
        onComplete: cb,
      })
    }
  }

  startStroke() {
    this.startTime = Date.now()
    this.firstTime = this.strokes[this.currentStroke][0].t
    this.rAF = requestAnimationFrame(this.draw)
  }

  toNextStroke() {
    this.currentStroke += 1

    // in between strokes we need to animate the pencil
    // from the last point of the last stroke to the first of the new stroke
    const lastStroke = this.strokes[this.currentStroke - 1]
    const oldPoint = lastStroke[lastStroke.length - 1]
    const newPoint = this.strokes[this.currentStroke][0]
    // in case we want the actual time between strokes, sometimes feels a bit long
    // const duration = newPoint.t && oldPoint.t ? newPoint.t - oldPoint.t : 500
    const duration = 250
    this.movePencil({
      from: this.xyToScreen(oldPoint.x, oldPoint.y),
      to: this.xyToScreen(newPoint.x, newPoint.y),
      time: duration,
      cb: this.startStroke,
    })
  }

  draw() {
    this.isDrawing = true
    // clearing the canvas
    const { canvas, context } = CanvasProxy
    context.fillRect(0, 0, canvas.width, canvas.height)

    const now = Date.now()
    const elapsed = now - this.startTime
    let isFinished = true

    const { sx, sy, width, height } = this.size

    // draw all previous strokes from the drawing
    const finished = this.strokes.filter((s, idx) => idx < this.currentStroke)
    finished.forEach((stroke) => {
      ToolProxy.drawStroke(CanvasProxy.context, stroke, sx, sy, width, height)
    })

    let lastPoint
    const stroke = this.strokes[this.currentStroke]
    // filter the current stroke by elapsed time
    const filtered = stroke.filter(s => s.t <= this.firstTime + elapsed)
    if (filtered.length !== stroke.length) isFinished = false
    if (filtered.length) lastPoint = filtered[filtered.length - 1]
    // draw the current stroke
    ToolProxy.drawStroke(CanvasProxy.context, filtered, sx, sy, width, height)

    if (lastPoint) {
      // update pencil
      emitter.emit('mousePosition', this.xyToScreen(lastPoint.x, lastPoint.y))
      this.lastPoint = lastPoint
    }

    if (!isFinished) {
      this.rAF = requestAnimationFrame(this.draw)
    } else if (this.strokes[this.currentStroke + 1]) {
      this.toNextStroke()
    } else {
      emitter.emit('autoDrawingFinished')
      this.isDrawing = false
    }
  }
}

export default new AutoDrawProxy()
