import React from 'react'
import { connect } from 'react-redux'
import '../scss/Canvas.scss'

import Canvas from './Canvas'

const CanvasHolder = () => (
  <div
    className="Canvas"
    style={{
      '--pointerEvents': 'none',
    }}
  >
    <Canvas />
  </div>
)

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(CanvasHolder)
