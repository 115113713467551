import { createSelector } from 'reselect'

const { isNaN } = Number

export const getAllCopyItems = createSelector(
  state => state.scenario.copy,
  copy =>
    copy.map(item => ({
      ...item,
      id: parseInt(item.id),
      level: parseInt(item.level),
    })),
)

export const getAvailableCopyItems = createSelector(
  getAllCopyItems,
  state => state.scenario.history,
  (copy, history) => copy.filter(item => !history.includes(item.index)),
)

export const getCurrentLevelCopyItems = createSelector(getAvailableCopyItems, copy =>
  copy.filter((item) => {
    // remove all copy with the wrong level
    if (!isNaN(item.level) && item.level !== 5) return false
    return true
  }))

export const getLatestItem = createSelector(
  state => state.scenario.item,
  state => state.scenario.latestItem,
  () => Date.now(),
  (item, latestItem) => (window.location.pathname.includes('colophon') ? latestItem : item),
)

export const getFirstItem = createSelector(
  state => state.scenario.copy,
  copy => (Array.isArray(copy) ? copy[0] : undefined),
)
