import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { emitter } from '../utils'
import '../scss/Pencil.scss'
import DocumentProxy from '../proxies/DocumentProxy'
import CanvasProxy from '../proxies/CanvasProxy'
import settings from '../settings'

class Pencil extends Component {
  state = {
    ...settings.pencilStartPosition,
  }

  componentDidMount() {
    emitter.on('mousePosition', this.handleMouseMove)
  }

  componentWillUnmount() {
    emitter.off('mousePosition', this.handleMouseMove)
  }

  handleMouseMove = ({ x, y }) => {
    this.setState({ x, y })
  }

  render() {
    const pencilClass = classNames('Pencil', {
      hidden:
        DocumentProxy.mouseMoved &&
        (!CanvasProxy.isMouseTarget && DocumentProxy.mouseMoved) &&
        !this.props.showIntroScreen,
    })
    const { x, y } = this.state

    return (
      <div
        className={pencilClass}
        style={{
          '--x': `${x - 5}px`,
          '--y': `${y - 125 + 2.5}px`,
        }}
      />
    )
  }
}

const mapStateToProps = state => ({
  showIntroScreen: state.app.showIntroScreen,
})

export default connect(mapStateToProps)(Pencil)
