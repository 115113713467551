export default {
  isDevMode: process.env.NODE_ENV !== 'production',
  googleAnalyticsTag: process.env.REACT_APP_GA_TAG_ID,
  sentryDSN: process.env.REACT_APP_SENTRY_DSN,
  defaultSilenceThreshold: 5000,
  newSilenceThreshold: (time) => {
    const ret = { 5000: 10000, 10000: 15000, 15000: 20000, 20000: 20000 }
    return ret[time]
  },
  css: {
    fontSize: '2.37em',
  },
  colors: {
    grey: '#505151',
  },
  pencilStartPosition: {
    x: window.innerWidth - 325,
    y: window.innerHeight - 32.5,
  },
  newDrawingResponseInterval: 2000,
  maximumCopyTime: 15000,
  penisReplacements: [
    'arm',
    'asparagus',
    'banana',
    'baseball bat',
    'broom',
    'candle',
    'cannon',
    'carrot',
    'clarinet',
    'finger',
    'hockey stick',
    'leg',
    'lollipop',
    'paintbrush',
    'popsicle',
    'toe',
    'tornado',
  ],
}
