// TODO: this should be a page to our store
import React from 'react'
import '../scss/ShopLink.scss'

const ShopLink = () => (
  <div className="ShopLink">
    Do you like these drawings? Buy a tea towel in our shop{' '}
    <a className="link" href="https://shop.studiomoniker.com/product/do-not-draw-a-penis-tea-towel">
      &#x2198;&nbsp;here
    </a>
  </div>
)

export default ShopLink
