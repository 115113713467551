/* eslint-disable object-curly-newline */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import '../scss/Home.scss'
import { resumeApp } from '../actions/app'
import CanvasHolder from './CanvasHolder'
import Pencil from './Pencil'
import Eraser from './Eraser'
import Subtitles from './Subtitles'
import ShopLink from './ShopLink'
import Nav from './Nav'

class Home extends Component {
  componentWillMount() {
    this.props.resumeApp()
  }

  render() {
    const homeClass = classNames('Home', {})
    const { showShopLink } = this.props
    return (
      <div className={homeClass}>
        <CanvasHolder />
        {!showShopLink && <Subtitles />}
        <Eraser />
        <Pencil />
        {showShopLink && <ShopLink />}
        <Nav />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  paused: state.app.paused,
  showShopLink: state.app.showShopLink,
})

const mapDispatchToProps = {
  resumeApp,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Home)
