import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactGA from 'react-ga'
import Tappable from 'react-tappable'
import classNames from 'classnames'

import { history } from '../store'
import '../scss/Nav.scss'

class Nav extends Component {
  gotoLink = (path) => {
    if (history.location.pathname !== path) {
      history.push(path)
      ReactGA.pageview(path)
    }
  }

  renderBackLink() {
    return (
      <Tappable
        onTap={() => {
          history.goBack()
        }}
      >
        <a
          className="button back"
          href=""
          onClick={(e) => {
            e.preventDefault()
          }}
        >
          Back
        </a>
      </Tappable>
    )
  }

  renderLink(path) {
    return (
      <Tappable
        onTap={() => {
          this.gotoLink(path)
        }}
      >
        <a className={`button ${path.toLowerCase()}`} href={path}>
          Colophon
        </a>
      </Tappable>
    )
  }

  render() {
    const navClass = classNames('Nav', {})

    const link =
      this.props.pathname === '/colophon' ? this.renderBackLink() : this.renderLink('colophon')

    return <div className={navClass}>{link}</div>
  }
}

const mapStateToProps = state => ({
  initialized: state.app.initialized,
  pathname: state.router.location.pathname,
})

export default connect(mapStateToProps)(Nav)
