import constants from '../actions/constants'

const initialState = {
  initialized: false,
  ready: false,
  paused: false,
  muted: false,
  showIntroScreen: !window.location.pathname.includes('colophon'),
  showShopLink: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.INIT_APP:
      return {
        ...state,
        initialized: true,
      }

    case constants.READY_APP:
      return {
        ...state,
        ready: true,
      }

    case constants.PAUSE_APP:
      return {
        ...state,
        paused: true,
      }

    case constants.RESUME_APP:
      return {
        ...state,
        paused: false,
      }

    case constants.HIDE_INTRO_SCREEN:
      return {
        ...state,
        showIntroScreen: false,
      }

    case constants.TOGGLE_MUTE:
      return {
        ...state,
        muted: !state.muted,
      }

    case constants.TOGGLE_SHOP_LINK:
      return {
        ...state,
        showShopLink: !state.showShopLink,
      }
    case constants.RESET_APP:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
