/* eslint-disable max-len */
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'

import '../scss/Colophon.scss'
import { pauseApp, resetApp } from '../actions/app'

import Nav from './Nav'
import HKW from './logos/HKW'
import Mozilla from './logos/Mozilla'

class Colophon extends Component {
  componentWillMount() {
    if (!this.props.paused) this.props.pauseApp()
  }

  render() {
    const colophonClass = classNames('Colophon', {})
    return (
      <Fragment>
        <div className={colophonClass}>
          <div className="content">
            <section className="text">
              <p>
                Donotdraw.com shows a{' '}
                <a href="https://github.com/studiomoniker/Quickdraw-appendix">dataset</a> of doodles
                collected by <a href="https://donotdrawapenis.com">Do Not Draw a Penis</a>.
              </p>

              <p>
                If you want to support this project, order{' '}
                <a href="https://shop.studiomoniker.com/product/do-not-draw-a-penis-tea-towel">
                  our tea towel
                </a>{' '}
                featuring 5k unique penises drawn by naughty people from all over the globe.
              </p>
              <p className="caption">
                <img className="tea-towel" src="/assets/images/tea_towel.jpg" alt="" />
                Buy our tea towel{' '}
                <a href="https://shop.studiomoniker.com/product/do-not-draw-a-penis-tea-towel">
                  &#x2198;&nbsp;here
                </a>
              </p>
            </section>
            <section className="footer">
              <div className="credits">
                <p>
                  Concept & development by <a href="https://studiomoniker.com/">Moniker</a>:<br />
                  Luna Maurer &amp; Roel Wouters
                  <br />
                  Jolana Sykorova
                  <br />
                  Thomas Boland
                  <br />
                  Tjerk Woudsma
                  <br />
                  Jae Perris
                  <br />
                </p>

                <p>
                  Commissioned by:
                  <br />
                  <a href="https://www.mozilla.org">Mozilla</a>, Brett Gaylor
                  <br />
                  <a href="https://www.hkw.de/en">Haus der Kulturen der Welt, Berlin (HKW)</a>,
                  Daniel Neugebauer
                </p>
              </div>
              <div className="logos">
                <a href="https://www.mozilla.org">
                  <Mozilla />
                </a>
                <a href="https://www.hkw.de/en">
                  <HKW />
                </a>
              </div>
            </section>
          </div>
        </div>
        <Nav />
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  paused: state.app.paused,
  muted: state.app.muted,
})

const mapDispatchToProps = {
  pauseApp,
  resetApp,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Colophon)
